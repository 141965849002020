import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    isDarkPalette: false,
    primaryLight: '#8187ff',
    primaryMain: '#3d5afe',
    primaryDark: '#0031ca',
    primaryContrastText: '#ffffff',
    primaryPaper: '#f5f5f5',
    secondaryLight: '#ff6434',
    secondaryMain: '#dd2c00',
    secondaryDark: '#a30000',
    secondaryContrastText: '#ffffff'
};

const changePaletteType = (state, action) => {
    return updateObject(state, { isDarkPalette: action.isDarkPalette })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CHANGE_PALETTE_TYPE: return changePaletteType(state, action);
        default:
            return state;
    }
};

export default reducer;