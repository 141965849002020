import React from 'react';
import { connect } from 'react-redux';

import classes from './Layout.module.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SmallToolbar from '../../components/Navigation/SmallToolbar/SmallToolbar';
import Footer from '../../components/UI/Footer/Footer';
import AlertDialog from '../../components/UI/AlertDialog/AlertDialog';
import useWindowDimensions from '../../shared/windowDimensions';
import useHttpErrorHandler from '../../hooks/http-error-handler';
import useHttpToken from '../../hooks/http-token-handler';
import * as actions from '../../store/actions/index';

const Layout = props => {
  const { height } = useWindowDimensions();
  const mainContentHeight = height - 71;

  useHttpToken(props.accessToken);
  const [error, clearError] = useHttpErrorHandler(props.userName, props.profile, props.onLogout);

  let errorDialog = null;
  if(error) {
    errorDialog = (
      <AlertDialog
        show={error !== null}
        onClosed={clearError}
        title={'Information'}
        message={error.message}
      />
    );
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Toolbar />
      <SmallToolbar />
      {errorDialog}
      <main className={classes.Content} style={{ height: `${mainContentHeight}px` }}>{props.children}</main>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userName: state.auth.userName,
    profile: state.auth.selectedProfile,
    accessToken: state.auth.accessToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: (userName, profile) => dispatch(actions.logout(userName, profile))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
