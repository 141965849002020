import _ from 'lodash';

import * as actionTypes from '../actions/actionTypes';
import { updateObject, filterObject, getExistingOpenedChilds } from '../../shared/utility';

const initialState = {
    dataSources: [],
    folders: [],
    reportList: [],
    activeReportInfos: {},
    activeReportKeys: [],
    reportInitialRunStatus: {},
    reportRefreshStatus: {},
    activeReportData: {},
    activeReportFilters: {},
    activeDDLevelInfos: {},
    defaultFolderId: 0,
    activeTabIndex: 0,
    error: null,
    loading: false
};

const initiateReportState = (state, action) => {
    return initialState;
};

const setLoadingStatus = (state, action) => {
    return updateObject(state, { loading: action.loading });
}

const setActiveTabIndex = (state, action) => {
    return updateObject(state, { activeTabIndex: action.activeTabIndex });
}

const removeOpenedReport = (state, action) => {
    let tabToDeleteIndex = state.activeReportKeys.findIndex(
        key => key === action.reportKey
    );

    const filteredActiveFilters = filterObject(state.activeReportFilters, action.reportKey);
    const filteredActiveReports = filterObject(state.activeReportInfos, action.reportKey);
    const filteredActiveDDLevelInfos = filterObject(state.activeDDLevelInfos, action.reportKey);
    const filteredInitialRunStatus = filterObject(state.reportInitialRunStatus, action.reportKey);
    const filteredRefreshStatus = filterObject(state.reportRefreshStatus, action.reportKey);
    const filteredReportData = filterObject(state.activeReportData, action.reportKey);
    const filteredActiveReportKeys = state.activeReportKeys.filter(key => key !== action.reportKey);

    return updateObject(state, {
        activeReportFilters: filteredActiveFilters,
        activeReportInfos: filteredActiveReports,
        activeDDLevelInfos: filteredActiveDDLevelInfos,
        reportInitialRunStatus: filteredInitialRunStatus,
        reportRefreshStatus: filteredRefreshStatus,
        activeReportData: filteredReportData,
        activeReportKeys: filteredActiveReportKeys,
        activeTabIndex: tabToDeleteIndex
    });
};

const setTabRefreshStatus = (state, action) => {
    const refreshStatus = _.cloneDeep(state.reportRefreshStatus);

    const reportInfo = state.activeReportInfos[action.reportKey];
    if(reportInfo.reportTypeCode === 'EF') {
        reportInfo.tabInfos.forEach(tab => {
            refreshStatus[action.reportKey][tab.tabGuid] = action.tabRefreshStatus;
        });
    }
    else if(reportInfo.reportTypeCode === 'DD') {
        state.activeDDLevelInfos[action.reportKey].activeLevelKeys.forEach(key => {
            refreshStatus[action.reportKey][key] = action.tabRefreshStatus;
        });
    }
    else {
        refreshStatus[action.reportKey][action.reportKey] = action.tabRefreshStatus;
    }
    return updateObject(state, {
        reportRefreshStatus: refreshStatus
    });
};

const fetchDataSourcesStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchDataSourcesSuccess = (state, action) => {
    return updateObject(state, {
        dataSources: action.dataSources,
        loading: false
    });
};

const fetchDataSourcesFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchFoldersStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchFoldersSuccess = (state, action) => {
    return updateObject(state, {
        folders: action.folders,
        defaultFolderId: action.defaultFolderId,
        loading: false
    });
};

const fetchFoldersFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchReportListStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchReportListSuccess = (state, action) => {
    return updateObject(state, {
        reportList: action.reportList,
        loading: false
    });
};

const fetchReportListFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchReportInfoStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchReportInfoSuccess = (state, action) => {
    const currentReportInfos = _.cloneDeep(state.activeReportInfos);
    const ddLevelInfos = _.cloneDeep(state.activeDDLevelInfos);
    const reportKeys = _.cloneDeep(state.activeReportKeys);
    const initialRunStatus = _.cloneDeep(state.reportInitialRunStatus);
    const refreshStatus = _.cloneDeep(state.reportRefreshStatus);
    const reportFilters = _.cloneDeep(state.activeReportFilters);
    const reportData = _.cloneDeep(state.activeReportData);
    const reportInfo = _.cloneDeep(action.reportInfo);

    initialRunStatus[action.reportKey] = false;

    if (reportInfo.reportTypeCode === 'EF') {
        reportInfo.tabInfos.forEach(tab => {
            if(reportData[action.reportKey]) {
                reportData[action.reportKey][tab.tabGuid] = [];
                refreshStatus[action.reportKey][tab.tabGuid] = false;
            }
            else {
                reportData[action.reportKey] = {
                    [tab.tabGuid]: []
                };
                refreshStatus[action.reportKey] = {
                    [tab.tabGuid]: false
                };
            }
        });
    }
    else if (reportInfo.reportTypeCode === 'DD') {
        if(reportData[action.reportKey]) {
            reportData[action.reportKey][reportInfo.reportGuid] = [];
            refreshStatus[action.reportKey][reportInfo.reportGuid] = false;
            ddLevelInfos[action.reportKey].selectedRowVals = {
                ...ddLevelInfos[action.reportKey].selectedRowVals,
                [reportInfo.reportGuid]: {}
            };
            ddLevelInfos[action.reportKey].activeLevelInfos = {
                ...ddLevelInfos[action.reportKey].activeLevelInfos,
                [reportInfo.reportGuid]: {
                    ...reportInfo.childReportDetail
                }
            };
            ddLevelInfos[action.reportKey].activeLevelChilds = {
                ...ddLevelInfos[action.reportKey].activeLevelChilds,
                [reportInfo.reportGuid]: [
                    ...reportInfo.childReportInfos
                ]
            };
            ddLevelInfos[action.reportKey].activeLevelKeys.push(reportInfo.reportGuid);
            ddLevelInfos[action.reportKey].activeLevelIndex = ddLevelInfos[action.reportKey].activeLevelKeys.length;
        }
        else {
            reportData[action.reportKey] = {
                [reportInfo.reportGuid]: []
            };
            refreshStatus[action.reportKey] = {
                [reportInfo.reportGuid]: false
            };
            ddLevelInfos[action.reportKey] = {
                selectedRowVals: {
                    [reportInfo.reportGuid]: {}
                },
                activeLevelInfos: {
                    [reportInfo.reportGuid]: {
                        ...reportInfo.childReportDetail
                    }
                },
                activeLevelChilds: {
                    [reportInfo.reportGuid]: [
                        ...reportInfo.childReportInfos
                    ]
                },
                activeLevelKeys: [reportInfo.reportGuid],
                activeLevelIndex: 0
            }
        }
    }
    else {
        reportData[action.reportKey] = {
            [action.reportKey]: []
        };
        refreshStatus[action.reportKey] = {
            [action.reportKey]: false
        };
    }
    
    if(reportInfo.reportTypeCode !== 'DD') {
        if(reportInfo.filterParameters) {
            reportInfo.filterParameters.forEach(param => {
                if(reportFilters[action.reportKey]) {
                    reportFilters[action.reportKey][param.parameterDisplayName] = {
                        ...param
                    };
                }
                else {
                    reportFilters[action.reportKey] = {
                        [param.parameterDisplayName]: {
                            ...param
                        }
                    };
                }
            });
        }
        else {
            reportFilters[action.reportKey] = {};
        }
    }
    else {
        if(reportInfo.reportGuid === 'f7bae277-471d-454c-8d4e-c5340c1d7f01') {
            if(reportInfo.childReportDetail.filterParameters) {
                reportInfo.childReportDetail.filterParameters.forEach(param => {
                    if(reportFilters[action.reportKey]) {
                        reportFilters[action.reportKey][param.parameterDisplayName] = {
                            ...param
                        };
                    }
                    else {
                        reportFilters[action.reportKey] = {
                            [param.parameterDisplayName]: {
                                ...param
                            }
                        };
                    }
                });
            }
            else {
                reportFilters[action.reportKey] = {};
            }
        }
    }
    
    currentReportInfos[action.reportKey] = reportInfo;
    reportKeys.push(action.reportKey);

    return updateObject(state, {
        activeReportInfos: currentReportInfos,
        activeDDLevelInfos: ddLevelInfos,
        activeReportKeys: reportKeys,
        reportInitialRunStatus: initialRunStatus,
        reportRefreshStatus: refreshStatus,
        activeReportFilters: reportFilters,
        activeReportData: reportData,
        activeTabIndex: reportKeys.length,
        loading: false
    });
};

const fetchReportInfoFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchReportDataStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchReportDataSuccess = (state, action) => {
    const updatedObject = {
        ...state,
        activeReportData: {
            ...state.activeReportData,
            [action.reportKey]: {
                ...state.activeReportData[action.reportKey],
                [action.tabGuid]: action.resultData,
            }
        },
        reportInitialRunStatus: {
            ...state.reportInitialRunStatus,
            [action.reportKey]: true
        },
        reportRefreshStatus: {
            ...state.reportRefreshStatus,
            [action.reportKey]: {
                ...state.reportRefreshStatus[action.reportKey],
                [action.tabGuid]: false
            }
        },
        loading: false
    };
    return updatedObject;
};

const fetchReportDataFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchReportFilterDataStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchReportFilterDataSuccess = (state, action) => {
    const updatedObject = {
        ...state,
        activeReportFilters: {
            ...state.activeReportFilters,
            [action.reportKey]: {
                ...state.activeReportFilters[action.reportKey],
                [action.filterKey]: {
                    ...state.activeReportFilters[action.reportKey][action.filterKey],
                    filterData: action.filterData
                },
            }
        },
        loading: false
    };
    return updatedObject;
};

const fetchReportFilterDataFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchReportFilterDataSkip = (state, action) => {
    const updatedObject = {
        ...state,
        loading: false
    };
    return updatedObject;
};

const changeReportFilterValues = (state, action) => {
    const updatedObject = {
        ...state,
        activeReportFilters: {
            ...state.activeReportFilters,
            [action.reportKey]: {
                ...state.activeReportFilters[action.reportKey],
                [action.filterDisplayName]: {
                    ...state.activeReportFilters[action.reportKey][action.filterDisplayName],
                    filterValues: action.filterValues
                },
            }
        }
    };
    return updatedObject;
};

const setDDActiveLevelIndex = (state, action) => {
    return updateObject(
                state,
                {
                    activeDDLevelInfos: {
                        ...state.activeDDLevelInfos,
                        [action.reportKey]: {
                            ...state.activeDDLevelInfos[action.reportKey],
                            activeLevelIndex: action.activeLevelIndex
                        }
                    }
                }
            );
}

const removeOpenedDDLevel = (state, action) => {
    let tabToDeleteIndex = state.activeDDLevelInfos[action.reportKey].activeLevelKeys.findIndex(
        key => key === action.levelKey
    );

    const filteredSelectedRowVals = filterObject(state.activeDDLevelInfos[action.reportKey].selectedRowVals, action.levelKey);
    const filteredLevelInfos = filterObject(state.activeDDLevelInfos[action.reportKey].activeLevelInfos, action.levelKey);
    const filteredLevelChilds = filterObject(state.activeDDLevelInfos[action.reportKey].activeLevelChilds, action.levelKey);
    const filteredActiveLevelKeys = state.activeDDLevelInfos[action.reportKey].activeLevelKeys.filter(key => key !== action.levelKey);
    const filteredReportData = filterObject(state.activeReportData[action.reportKey], action.levelKey);

    return updateObject(state, {
        activeDDLevelInfos: {
            ...state.activeDDLevelInfos,
            [action.reportKey]: {
                ...state.activeDDLevelInfos[action.reportKey],
                selectedRowVals: filteredSelectedRowVals,
                activeLevelInfos: filteredLevelInfos,
                activeLevelChilds: filteredLevelChilds,
                activeLevelKeys: filteredActiveLevelKeys,
                activeLevelIndex: tabToDeleteIndex - 1
            }
        },
        activeReportData: {
            ...state.activeReportData,
            [action.reportKey]: filteredReportData
        }
    });
};

const fetchDDChildReportInfoStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchDDChildReportInfoSuccess = (state, action) => {
    const reportInfo = _.cloneDeep(action.reportInfo.childReportDetail);
    const ddLevelGuid = action.reportInfo.reportGuid;
    const childInfos = [
        ...action.reportInfo.childReportInfos
    ];
    let updatedObject = _.cloneDeep(state);

    const openedChilds = getExistingOpenedChilds(updatedObject.activeDDLevelInfos[action.reportKey], action.ddParentKey, []);
    openedChilds.reverse();
    openedChilds.forEach((child, index) => {
        let tabToDeleteIndex = updatedObject.activeDDLevelInfos[action.reportKey].activeLevelKeys.findIndex(
            key => key === child
        );
        if(tabToDeleteIndex !== -1) {
            const removeAction = {
                reportKey: action.reportKey,
                levelKey: child
            };
            updatedObject = removeOpenedDDLevel(updatedObject, removeAction);
        }
    });

    updatedObject = {
        ...updatedObject,
        activeDDLevelInfos: {
            ...updatedObject.activeDDLevelInfos,
            [action.reportKey]: {
                ...updatedObject.activeDDLevelInfos[action.reportKey],
                selectedRowVals: {
                    ...updatedObject.activeDDLevelInfos[action.reportKey].selectedRowVals,
                    [action.ddParentKey]: {
                        ...action.ddParentVals
                    },
                    [ddLevelGuid]: {}
                },
                activeLevelInfos: {
                    ...updatedObject.activeDDLevelInfos[action.reportKey].activeLevelInfos,
                    [ddLevelGuid]: reportInfo
                },
                activeLevelChilds: {
                    ...updatedObject.activeDDLevelInfos[action.reportKey].activeLevelChilds,
                    [ddLevelGuid]: childInfos
                },
                activeLevelKeys: [
                    ...updatedObject.activeDDLevelInfos[action.reportKey].activeLevelKeys,
                    ddLevelGuid
                ],
                activeLevelIndex: updatedObject.activeDDLevelInfos[action.reportKey].activeLevelKeys.length
            }
        },
        activeReportData: {
            ...updatedObject.activeReportData,
            [action.reportKey]: {
                ...updatedObject.activeReportData[action.reportKey],
                [ddLevelGuid]: []
            }
        },
        reportRefreshStatus: {
            ...updatedObject.reportRefreshStatus,
            [action.reportKey]: {
                ...updatedObject.reportRefreshStatus[action.reportKey],
                [ddLevelGuid]: true
            }
        },
        loading: false
    };

    return updatedObject;
};

const fetchDDChildReportInfoFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const fetchDDChildReportDataStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const fetchDDChildReportDataSuccess = (state, action) => {
    const updatedObject = {
        ...state,
        activeReportData: {
            ...state.activeReportData,
            [action.reportKey]: {
                ...state.activeReportData[action.reportKey],
                [action.tabGuid]: action.resultData,
            }
        },
        reportRefreshStatus: {
            ...state.reportRefreshStatus,
            [action.reportKey]: {
                ...state.reportRefreshStatus[action.reportKey],
                [action.tabGuid]: false
            }
        },
        loading: false
    };
    return updatedObject;
};

const fetchDDChildReportDataFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const exportReportDataStart = (state, action) => {
    return updateObject(state, { error: null, loading: true });
};

const exportReportDataSuccess = (state, action) => {
    const updatedObject = {
        ...state,
        loading: false
    };
    return updatedObject;
};

const exportReportDataFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });
};

const clearReportError = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIATE_REPORT_STATE: return initiateReportState(state, action);
        case actionTypes.FETCH_DATASOURCES_START: return fetchDataSourcesStart(state, action);
        case actionTypes.FETCH_DATASOURCES_SUCCESS: return fetchDataSourcesSuccess(state, action);
        case actionTypes.FETCH_DATASOURCES_FAIL: return fetchDataSourcesFail(state, action);
        case actionTypes.FETCH_FOLDERS_START: return fetchFoldersStart(state, action);
        case actionTypes.FETCH_FOLDERS_SUCCESS: return fetchFoldersSuccess(state, action);
        case actionTypes.FETCH_FOLDERS_FAIL: return fetchFoldersFail(state, action);
        case actionTypes.FETCH_REPORT_LIST_START: return fetchReportListStart(state, action);
        case actionTypes.FETCH_REPORT_LIST_SUCCESS: return fetchReportListSuccess(state, action);
        case actionTypes.FETCH_REPORT_LIST_FAIL: return fetchReportListFail(state, action);
        case actionTypes.FETCH_REPORT_INFO_START: return fetchReportInfoStart(state, action);
        case actionTypes.FETCH_REPORT_INFO_SUCCESS: return fetchReportInfoSuccess(state, action);
        case actionTypes.FETCH_REPORT_INFO_FAIL: return fetchReportInfoFail(state, action);
        case actionTypes.FETCH_REPORT_DATA_START: return fetchReportDataStart(state, action);
        case actionTypes.FETCH_REPORT_DATA_SUCCESS: return fetchReportDataSuccess(state, action);
        case actionTypes.FETCH_REPORT_DATA_FAIL: return fetchReportDataFail(state, action);
        case actionTypes.FETCH_REPORT_FILTER_DATA_START: return fetchReportFilterDataStart(state, action);
        case actionTypes.FETCH_REPORT_FILTER_DATA_SUCCESS: return fetchReportFilterDataSuccess(state, action);
        case actionTypes.FETCH_REPORT_FILTER_DATA_FAIL: return fetchReportFilterDataFail(state, action);
        case actionTypes.FETCH_REPORT_FILTER_DATA_SKIP: return fetchReportFilterDataSkip(state, action);
        case actionTypes.REMOVE_OPENED_REPORT: return removeOpenedReport(state, action);
        case actionTypes.SET_ACTIVE_TAB_INDEX: return setActiveTabIndex(state, action);
        case actionTypes.CHANGE_REPORT_FILTER_VALUES: return changeReportFilterValues(state, action);
        case actionTypes.SET_LOADING_STATUS: return setLoadingStatus(state, action);
        case actionTypes.SET_TAB_REFRESH_STATUS: return setTabRefreshStatus(state, action);
        case actionTypes.SET_ACTIVE_DD_LEVEL_INDEX: return setDDActiveLevelIndex(state, action);
        case actionTypes.REMOVE_OPENED_DD_LEVEL: return removeOpenedDDLevel(state, action);
        case actionTypes.FETCH_DDCHILD_REPORT_INFO_START: return fetchDDChildReportInfoStart(state, action);
        case actionTypes.FETCH_DDCHILD_REPORT_INFO_SUCCESS: return fetchDDChildReportInfoSuccess(state, action);
        case actionTypes.FETCH_DDCHILD_REPORT_INFO_FAIL: return fetchDDChildReportInfoFail(state, action);
        case actionTypes.FETCH_DDCHILD_REPORT_DATA_START: return fetchDDChildReportDataStart(state, action);
        case actionTypes.FETCH_DDCHILD_REPORT_DATA_SUCCESS: return fetchDDChildReportDataSuccess(state, action);
        case actionTypes.FETCH_DDCHILD_REPORT_DATA_FAIL: return fetchDDChildReportDataFail(state, action);
        case actionTypes.EXPORT_REPORT_DATA_START: return exportReportDataStart(state, action);
        case actionTypes.EXPORT_REPORT_DATA_SUCCESS: return exportReportDataSuccess(state, action);
        case actionTypes.EXPORT_REPORT_DATA_FAIL: return exportReportDataFail(state, action);
        case actionTypes.CLEAR_REPORT_ERROR: return clearReportError(state,action);
        default:
            return state;
    }
};

export default reducer;