import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import dotenv from 'dotenv';
import { registerLicense } from '@syncfusion/ej2-base';

import './index.css';
// import './styles.scss';

import GlobalTheme from './hoc/GlobalTheme/GlobalTheme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import authReducer from './store/reducers/auth';
import themeReducer from './store/reducers/theme';
import reportsReducer from './store/reducers/reports';

dotenv.config();

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  reports: reportsReducer
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
      <BrowserRouter>
        <GlobalTheme>
          <App />
        </GlobalTheme>
      </BrowserRouter>
    </Provider>
);

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFadVZJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhXX9XdXZWTmFaWUA=');

ReactDOM.render(
  app,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
