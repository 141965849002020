import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';

import classes from './AccountMenu.module.css';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, ListItemIcon, ListItemText, Box, FormControlLabel, Switch } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';

import * as actions from '../../../store/actions/index';

const StyledBox = styled(Box)`
        color: ${props => props.primarylight};
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        display: block;
        &:hover,
        &:active {
            color: ${props => props.secondarymain};
        }
        
        @media (min-width: 500px) {
            color: ${props => props.primarycontrasttext};
            height: 100%;
            padding: 5px;
            border-bottom: 4px solid transparent;
            &:hover,
            &:active {
                background-color: ${props => props.primarylight};
                border-bottom: 4px solid ${props => props.secondarymain};
                color: ${props => props.primarycontrasttext};
                text-decoration: none;
            }
        }
    `;

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const AccountMenu = props => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = () => {
        props.changePaletteType(!props.isDarkPalette);
    }

    const handleLogout = () => {
        history.push('/logout');
    }

    return (
        <li className={classes.NavigationItem}>
            <StyledBox className={classes.AccountMenu} onClick={handleClick}
                aria-controls="customized-menu"
                aria-haspopup="true"
                primarylight={props.primaryLight}
                primarycontrasttext={props.primaryContrastText}
                secondarymain={props.secondaryMain}
                >{props.children}
            </StyledBox>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.isDarkPalette}
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                                size="small"
                            />
                        }
                        label={
                        <Box component="div" fontSize={14} style={{paddingLeft: '20px'}}>Dark Mode</Box>
                        }
                    />
                </StyledMenuItem>
                <StyledMenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={<Box component="div" fontSize={14}>
                        Logout
                      </Box>} />
                </StyledMenuItem>
            </StyledMenu>
        </li>
    );
}

const mapStateToProps = state => {
    return {
        isDarkPalette: state.theme.isDarkPalette,
        primaryLight: state.theme.primaryLight,
        primaryContrastText: state.theme.primaryContrastText,
        secondaryMain: state.theme.secondaryMain,
        userDisplayName: state.auth.displayName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changePaletteType: (isDarkPalette) =>
            dispatch(actions.changePaletteType(isDarkPalette))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);