import React from 'react';
import { connect } from 'react-redux';

// import { Tooltip } from '@material-ui/core';
// import { Home } from '@material-ui/icons';
import { ViewList, Person } from '@material-ui/icons';

import classes from './SmallToolbar.module.css';
import Logo from '../../Logo/Logo';
import AccountMenu from '../../Navigation/AccountMenu/AccountMenu';
import NavigationItems from '../NavigationItems/NavigationItems';
import NavigationItem from '../NavigationItems/NavigationItem/NavigationItem';

const Toolbar = props => {
    const leftNavItems = (
        <React.Fragment>
            {/* <NavigationItem link="/home" exact>
                <Tooltip title="Home">
                    <div className={classes.IconDiv}>
                        <Home style={{ fill: props.primaryContrastText }} />
                    </div>
                </Tooltip>
            </NavigationItem> */}
            <NavigationItem link="/reports">
                <div className={classes.IconDiv}>
                    <ViewList style={{ fill: props.primaryContrastText }} />
                </div>
            </NavigationItem>
        </React.Fragment>);

    const rightNavItems = (
        <AccountMenu>
            <div className={classes.IconDiv}>
                <Person style={{ fill: props.primaryContrastText }} />
            </div>
        </AccountMenu>);

    return (
        <header className={classes.SmallToolbar}
            style={{ backgroundColor: props.primaryMain }}>
            <div className={classes.LeftNavItems}>
                <div className={classes.Logo}>
                    <Logo height={30} />
                </div>
                <nav>
                    <NavigationItems>{leftNavItems}</NavigationItems>
                </nav>
            </div>
            <div className={classes.RightNavItems}>
                <nav>
                    <NavigationItems>{rightNavItems}</NavigationItems>
                </nav>
            </div>
        </header>
    );
}

const mapStateToProps = state => {
    return {
        primaryMain: state.theme.primaryMain,
        primaryContrastText: state.theme.primaryContrastText,
        userDisplayName: state.auth.displayName
    };
};

export default connect(mapStateToProps)(Toolbar);