import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import classes from './NavigationItem.module.css';

const StyledNavLink = styled(NavLink)`
            color: ${props => props.primarylight};
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            display: block;
            &:hover,
            &:active,
            &.active {
                color: ${props => props.secondarymain};
            }

            @media (min-width: 500px) {
                color: ${props => props.primarycontrasttext};
                height: 100%;
                padding: 5px;
                border-bottom: 4px solid transparent;
                &:hover,
                &:active,
                &.active {
                    background-color: ${props => props.primarylight};
                    border-bottom: 4px solid ${props => props.secondarymain};
                    color: ${props => props.primarycontrasttext};
                    text-decoration: none;
                }
            }
        `;

const NavigationItem = (props) => {
    
    return (
        <li className={classes.NavigationItem}>
            <StyledNavLink
                to={props.link}
                exact={props.exact}
                activeClassName='active'
                primarylight={props.primaryLight}
                primarycontrasttext={props.primaryContrastText}
                secondarymain={props.secondaryMain}
            >{props.children}</StyledNavLink>
        </li>
    );
}

const mapStateToProps = state => {
    return {
        primaryLight: state.theme.primaryLight,
        primaryContrastText: state.theme.primaryContrastText,
        secondaryMain: state.theme.secondaryMain,
    };
};

export default connect(mapStateToProps)(NavigationItem);