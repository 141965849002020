import { useEffect } from 'react';

import axios from '../axios-instance';

const useHttpToken = (accessToken) => {
  const { request } = axios.interceptors;
  let tokenAdded = false;
  
  const reqInterceptor = request.use(req => {
    // add authorization header with jwt token if available
    if(accessToken !== null) {
      req.headers.Authorization = `Bearer ${accessToken}`
      tokenAdded = true;
    }
    else {
      delete req.headers.common['Authorization'];
    }
    return req;
  });
  
  useEffect(() => {
    return () => {
      request.eject(reqInterceptor);
    };
  }, [request, reqInterceptor]);

  return tokenAdded;
}

export default useHttpToken;