export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const getConversionOperator = (operator) => {
  let symbol = "=";
  switch (operator) {
    case "Equals":
      symbol = "=";
      break;
    case "NotEquals":
      symbol = "!=";
      break;
    case "LesserThan":
      symbol = "<";
      break;
    case "LesserThanOrEquals":
      symbol = "<=";
      break;
    case "GreaterThan":
      symbol = ">";
      break;
    case "GreaterThanOrEquals":
      symbol = ">=";
      break;
    case "In":
      symbol = "IN";
      break;
    case "NotIn":
      symbol = "NOT IN";
      break;
    case "IsNull":
      symbol = "IS NULL";
      break;
    case "IsNotNull":
      symbol = "IS NOT NULL";
      break;
    case "Like":
      symbol = "LIKE";
      break;
    case "NotLike":
      symbol = "NOT LIKE";
      break;
    case "Contains":
      symbol = "CONTAINS";
      break;
    case "StartsWith":
      symbol = "Starts With";
      break;
    case "EndsWith":
      symbol = "Ends With";
      break;
    case "InRange":
      symbol = "IN RANGE";
      break;
    default:
      symbol = "";
  }
  return symbol;
};

export const filterObject = (actualObject, filterKey) => {
  return Object.keys(actualObject)
    .filter((key) => ![filterKey].includes(key))
    .reduce((obj, key) => {
      obj[key] = actualObject[key];
      return obj;
    }, {});
};

export const updateParentSelectedVals = (
  allLevelChilds,
  allParentSelectedVals,
  tabGuid
) => {
  const parentSelectedVals = Object.keys(allLevelChilds)
    .filter((key) =>
      allLevelChilds[key].some((info) => info.childGuid === tabGuid)
    )
    .reduce((obj, key) => {
      obj[key] = allParentSelectedVals[key];
      return obj;
    }, {});
  Object.keys(parentSelectedVals).forEach((key) => {
    let nestedSelectedVals = updateParentSelectedVals(
      allLevelChilds,
      allParentSelectedVals,
      key
    );
    Object.keys(nestedSelectedVals).forEach((nestedKey) => {
      parentSelectedVals[nestedKey] = nestedSelectedVals[nestedKey];
    });
  });
  return parentSelectedVals;
};

export const ddSelectedRowVals = (parentSelectedVals) => {
  let selectedRowVals = [];
  let cellValues = [];
  if (parentSelectedVals && Object.keys(parentSelectedVals).length > 0) {
    selectedRowVals = Object.keys(parentSelectedVals).map((key) => {
      Object.keys(parentSelectedVals[key]).forEach((col) => {
        cellValues.push({
          columnName: col,
          columnValue: parentSelectedVals[key][col].toString(),
        });
      });
      // cellValues = Object.keys(parentSelectedVals[key]).map(col => {
      //     return {
      //         columnName: col,
      //         columnValue: parentSelectedVals[key][col]
      //     };
      // });
      return {
        levelGuid: key,
        cellInfos: cellValues,
      };
    });
  }
  return selectedRowVals;
};

export const getExistingOpenedChilds = (
  ddLevelInfos,
  levelKey,
  initialChildList
) => {
  const childInfos = ddLevelInfos.activeLevelChilds[levelKey];
  const childGuid = childInfos.length > 0 ? childInfos[0].childGuid : "";
  if (childGuid !== "") {
    if (!initialChildList.includes(childGuid)) {
      initialChildList.push(childGuid);
    }
    if (
      ddLevelInfos.activeLevelChilds[childGuid] &&
      Object.keys(ddLevelInfos.activeLevelChilds[childGuid]).length > 0
    ) {
      return getExistingOpenedChilds(ddLevelInfos, childGuid, initialChildList);
    }
  }
  return initialChildList;
};

export const getNumericFormatString = (
  decimalValue,
  separatorType,
  decimalPrecision,
  isPercentageField
) => {
  let formatString = "##0";
  if (decimalPrecision > 0) {
    formatString = formatString + ".";
    let i = 0;
    for (i = 1; i <= decimalPrecision; i++) {
      formatString = formatString + "0";
    }
  }
  if (isPercentageField) {
    formatString = formatString + "%";
  }

  if (separatorType === "MillionSeparator") {
    formatString = "###,###," + formatString;
  } else if (separatorType === "LakhsSeparator") {
    if (decimalValue < 0) {
      formatString =
        "[<=-10000000]-###,###," +
        formatString +
        ";[<=-100000]-#,###," +
        formatString +
        ";##," +
        formatString;
    } else {
      formatString =
        "[>=10000000]###,###," +
        formatString +
        ";[>=100000]#,###," +
        formatString +
        ";##," +
        formatString;
    }
  } else {
    formatString = "######" + formatString;
  }
  return formatString;
};

export const getNumericFormatIntlString = (
  decimalPrecision,
  currencySymbolType
) => {
  if (currencySymbolType !== "None") {
    return "C" + decimalPrecision;
  }
  return "N" + decimalPrecision;
};

export const isNumericField = (columnType) => {
  if (
    columnType === "Decimal" ||
    columnType === "Double" ||
    columnType === "Int16" ||
    columnType === "Int32" ||
    columnType === "Int64" ||
    columnType === "Single" ||
    columnType === "UInt16" ||
    columnType === "UInt32" ||
    columnType === "UInt64"
  ) {
    return true;
  }
  return false;
};
