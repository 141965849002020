export const CHANGE_PALETTE_TYPE = 'CHANGE_PALETTE_TYPE';

export const FETCH_LICENSE_INFO_START = 'FETCH_LICENSE_INFO_START';
export const FETCH_LICENSE_INFO_SUCCESS = 'FETCH_LICENSE_INFO_SUCCESS';
export const FETCH_LICENSE_INFO_FAIL = 'FETCH_LICENSE_INFO_FAIL';

export const ACTIVATE_LICENSE_INFO_START = 'ACTIVATE_LICENSE_INFO_START';
export const ACTIVATE_LICENSE_INFO_SUCCESS = 'ACTIVATE_LICENSE_INFO_SUCCESS';
export const ACTIVATE_LICENSE_INFO_FAIL = 'ACTIVATE_LICENSE_INFO_FAIL';

export const FETCH_PROFILES_START = 'FETCH_PROFILES_START';
export const FETCH_PROFILES_SUCCESS = 'FETCH_PROFILES_SUCCESS';
export const FETCH_PROFILES_FAIL = 'FETCH_PROFILES_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const FORCE_LOGOUT_SESSION_START = 'FORCE_LOGOUT_SESSION_START';
export const FORCE_LOGOUT_SESSION_SUCCESS = 'FORCE_LOGOUT_SESSION_SUCCESS';
export const FORCE_LOGOUT_SESSION_FAIL = 'FORCE_LOGOUT_SESSION_FAIL';
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';

export const INITIATE_REPORT_STATE = 'INITIATE_REPORT_STATE';

export const FETCH_DATASOURCES_START = 'FETCH_DATASOURCES_START';
export const FETCH_DATASOURCES_SUCCESS = 'FETCH_DATASOURCES_SUCCESS';
export const FETCH_DATASOURCES_FAIL = 'FETCH_DATASOURCES_FAIL';

export const FETCH_FOLDERS_START = 'FETCH_FOLDERS_START';
export const FETCH_FOLDERS_SUCCESS = 'FETCH_FOLDERS_SUCCESS';
export const FETCH_FOLDERS_FAIL = 'FETCH_FOLDERS_FAIL';

export const FETCH_REPORT_LIST_START = 'FETCH_REPORT_LIST_START';
export const FETCH_REPORT_LIST_SUCCESS = 'FETCH_REPORT_LIST_SUCCESS';
export const FETCH_REPORT_LIST_FAIL = 'FETCH_REPORT_LIST_FAIL';

export const FETCH_REPORT_INFO_START = 'FETCH_REPORT_INFO_START';
export const FETCH_REPORT_INFO_SUCCESS = 'FETCH_REPORT_INFO_SUCCESS';
export const FETCH_REPORT_INFO_FAIL = 'FETCH_REPORT_INFO_FAIL';

export const FETCH_REPORT_DATA_START = 'FETCH_REPORT_DATA_START';
export const FETCH_REPORT_DATA_SUCCESS = 'FETCH_REPORT_DATA_SUCCESS';
export const FETCH_REPORT_DATA_FAIL = 'FETCH_REPORT_DATA_FAIL';

export const FETCH_REPORT_FILTER_DATA_START = 'FETCH_REPORT_FILTER_DATA_START';
export const FETCH_REPORT_FILTER_DATA_SUCCESS = 'FETCH_REPORT_FILTER_DATA_SUCCESS';
export const FETCH_REPORT_FILTER_DATA_FAIL = 'FETCH_REPORT_FILTER_DATA_FAIL';
export const FETCH_REPORT_FILTER_DATA_SKIP = 'FETCH_REPORT_FILTER_DATA_SKIP';

export const SET_ACTIVE_TAB_INDEX = 'SET_ACTIVE_TAB_INDEX';
export const REMOVE_OPENED_REPORT = 'REMOVE_OPENED_REPORT';
export const CHANGE_REPORT_FILTER_VALUES = 'CHANGE_REPORT_FILTER_VALUES';
export const SET_LOADING_STATUS = 'SET_LOADING_STATUS';
export const SET_INITIAL_RUN_STATUS = 'SET_INITIAL_RUN_STATUS';
export const SET_TAB_REFRESH_STATUS = 'SET_TAB_REFRESH_STATUS';

export const SET_ACTIVE_DD_LEVEL_INDEX = 'SET_ACTIVE_DD_LEVEL_INDEX';
export const REMOVE_OPENED_DD_LEVEL = 'REMOVE_OPENED_DD_LEVEL';

export const FETCH_DDCHILD_REPORT_INFO_START = 'FETCH_DDCHILD_REPORT_INFO_START';
export const FETCH_DDCHILD_REPORT_INFO_SUCCESS = 'FETCH_DDCHILD_REPORT_INFO_SUCCESS';
export const FETCH_DDCHILD_REPORT_INFO_FAIL = 'FETCH_DDCHILD_REPORT_INFO_FAIL';

export const FETCH_DDCHILD_REPORT_DATA_START = 'FETCH_DDCHILD_REPORT_DATA_START';
export const FETCH_DDCHILD_REPORT_DATA_SUCCESS = 'FETCH_DDCHILD_REPORT_DATA_SUCCESS';
export const FETCH_DDCHILD_REPORT_DATA_FAIL = 'FETCH_DDCHILD_REPORT_DATA_FAIL';

export const EXPORT_REPORT_DATA_START = 'EXPORT_REPORT_DATA_START';
export const EXPORT_REPORT_DATA_SUCCESS = 'EXPORT_REPORT_DATA_SUCCESS';
export const EXPORT_REPORT_DATA_FAIL = 'EXPORT_REPORT_DATA_FAIL';

export const CLEAR_REPORT_ERROR = 'CLEAR_REPORT_ERROR';