import React, { useEffect, Suspense, useCallback } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './hoc/Layout/Layout';
import Auth from './containers/Auth/Auth';
// import ActivateLicense from './containers/Auth/ActivateLicense/ActivateLicense';
import Logout from './containers/Auth/Logout/Logout';
import * as actions from './store/actions/index';

const Home = React.lazy(() => {
  return import('./containers/Home/Home');
});

const Reports = React.lazy(() => {
  return import('./containers/Reports/Reports');
});


const App = props => {
  const { licenseInfo, onFetchLicenseInfo, onTryAutoSignup } = props;

  const onFetchLicense = useCallback(() => {
    onFetchLicenseInfo();
  }, [onFetchLicenseInfo]);

  useEffect(() => onFetchLicense(), [onFetchLicense]);

  useEffect(() => {
    if (licenseInfo && licenseInfo.hasValidLicense) {
      onTryAutoSignup();
    }
  }, [licenseInfo, onTryAutoSignup]);

  // const defaultPage = (licenseInfo && licenseInfo.hasValidLicense ? Auth : ActivateLicense);
  const defaultPage = Auth;

  let routes = (
    <Switch>
      <Route path="/" exact component={defaultPage} />
      <Redirect to="/" />
    </Switch>
  );

  if (props.isAuthenticated) {
    routes = (
      <Switch>
        <Route path="/reports" render={props => <Reports {...props} />} />
        <Route path="/home" render={props => <Home {...props} />} />
        <Route path="/logout" component={Logout} />
        <Redirect to="/reports" />
      </Switch>
    );
  }

  const suspense = (<Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>);

  return (
    <div>
      {(props.isAuthenticated ? <Layout>{suspense}</Layout> : suspense)}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.accessToken !== null,
    licenseInfo: state.auth.licenseInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onFetchLicenseInfo: () => dispatch(actions.fetchLicenseInfo())
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
