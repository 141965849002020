import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const AlertDialog = props => {
    return (
        <Dialog
            open={props.show}
            onClose={props.onClosed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {(props.isConfirmDialog ? (
                    <React.Fragment>
                        <Button onClick={props.onConfirmed} color="primary" autoFocus>
                            YES
                        </Button>
                        <Button onClick={props.onClosed} color="secondary" autoFocus>
                            NO
                        </Button>
                    </React.Fragment>
                ) : (
                    <Button onClick={props.onClosed} color="primary" autoFocus>
                        OK
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;