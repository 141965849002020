import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    defaultProfile: '',
    profiles: [],
    accessToken: null,
    refreshToken: null,
    userName: null,
    displayName: null,
    email: null,
    selectedProfile: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    licenseInfo: {}
};

const fetchLicenseInfoStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const fetchLicenseInfoSuccess = (state, action) => {
    return updateObject( state, { 
        licenseInfo: action.licenseInfo,
        error: null,
        loading: false
     } );
};

const fetchLicenseInfoFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const activateLicenseInfoStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const activateLicenseInfoSuccess = (state, action) => {
    return updateObject( state, { 
        licenseInfo: action.licenseInfo,
        error: null,
        loading: false
     } );
};

const activateLicenseInfoFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const fetchProfilesStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchProfilesSuccess = ( state, action ) => {
    return updateObject( state, {
        profiles: action.profiles,
        defaultProfile: action.defaultProfile,
        loading: false
    } );
};

const fetchProfilesFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        userName: action.userName,
        displayName: action.displayName,
        email: action.email,
        selectedProfile: action.selectedProfile,
        error: null,
        loading: false
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const forceLogoutSessionStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const forceLogoutSessionSuccess = (state, action) => {
    return updateObject( state, {
        error: null,
        loading: false
     });
};

const forceLogoutSessionFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const logoutStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const logoutSuccess = (state, action) => {
    return updateObject( state, { 
        accessToken: null,
        refreshToken: null,
        userName: null,
        displayName: null,
        email: null,
        error: null,
        loading: false
     } );
};

const logoutFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const clearAuthError = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.FORCE_LOGOUT_SESSION_START: return forceLogoutSessionStart(state, action);
        case actionTypes.FORCE_LOGOUT_SESSION_SUCCESS: return forceLogoutSessionSuccess(state, action);
        case actionTypes.FORCE_LOGOUT_SESSION_FAIL: return forceLogoutSessionFail(state, action);
        case actionTypes.LOGOUT_START: return logoutStart(state, action);
        case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state, action);
        case actionTypes.LOGOUT_FAIL: return logoutFail(state, action);
        case actionTypes.FETCH_PROFILES_START: return fetchProfilesStart( state, action );
        case actionTypes.FETCH_PROFILES_SUCCESS: return fetchProfilesSuccess( state, action );
        case actionTypes.FETCH_PROFILES_FAIL: return fetchProfilesFail( state, action );
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.CLEAR_AUTH_ERROR: return clearAuthError(state,action);
        case actionTypes.FETCH_LICENSE_INFO_START: return fetchLicenseInfoStart( state, action );
        case actionTypes.FETCH_LICENSE_INFO_SUCCESS: return fetchLicenseInfoSuccess( state, action );
        case actionTypes.FETCH_LICENSE_INFO_FAIL: return fetchLicenseInfoFail( state, action );
        case actionTypes.ACTIVATE_LICENSE_INFO_START: return activateLicenseInfoStart( state, action );
        case actionTypes.ACTIVATE_LICENSE_INFO_SUCCESS: return activateLicenseInfoSuccess( state, action );
        case actionTypes.ACTIVATE_LICENSE_INFO_FAIL: return activateLicenseInfoFail( state, action );
        default:
            return state;
    }
};

export default reducer;