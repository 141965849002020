import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

const Logout = props => {
  const { userName, profile, onLogout } = props;

  useEffect(() => {
    onLogout(userName, profile);
  }, [userName, profile, onLogout]);

  return <Redirect to="/" />;
};

const mapStateToProps = state => {
  return {
      userName: state.auth.userName,
      profile: state.auth.selectedProfile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: (userName, profile) => dispatch(actions.logout(userName, profile))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
