import { useState, useEffect } from 'react';

import axios from '../axios-instance';

const useHttpError = (userName, profile, onLogout) => {
  const { request, response } = axios.interceptors;
  const [error, setError] = useState(null);

  const errorConfirmedHandler = () => {
    setError(null);
  };

  const reqInterceptor = request.use(req => {
    setError(null);
    return req;
  });
  const resInterceptor = response.use(
    res => {
      if (res.status === 401) {
        // auto logout if 401 response returned from api
        onLogout(userName, profile);
      }
      return res;
    },
    err => {
      if (err.response) {
        if (err.response.status === 401) {
          // auto logout if 401 response returned from api
          onLogout(userName, profile);
        }
      }
      const error = err.message || err.response.status;
      setError({ message: error });
    }
  );

  useEffect(() => {
    return () => {
      request.eject(reqInterceptor);
      response.eject(resInterceptor);
    };
  }, [request, response, reqInterceptor, resInterceptor]);

  return [error, errorConfirmedHandler];
}

export default useHttpError;