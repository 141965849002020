import React from 'react';
import { connect } from 'react-redux';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const GlobalTheme = props => {
    const theme = createMuiTheme({
        palette: {
            type: (props.isDarkPalette ? 'dark' : 'light'),
            primary: {
                light: props.primaryLight,
                main: props.primaryMain,
                dark: props.primaryDark,
                contrastText: props.primaryContrastText,
            },
            secondary: {
                light: props.secondaryLight,
                main: props.secondaryMain,
                dark: props.secondaryDark,
                contrastText: props.secondaryContrastText,
            },
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );
}

const mapStateToProps = state => {
    return {
        isDarkPalette: state.theme.isDarkPalette,
        primaryLight: state.theme.primaryLight,
        primaryMain: state.theme.primaryMain,
        primaryDark: state.theme.primaryDark,
        primaryContrastText: state.theme.primaryContrastText,
        secondaryLight: state.theme.secondaryLight,
        secondaryMain: state.theme.secondaryMain,
        secondaryDark: state.theme.secondaryDark,
        secondaryContrastText: state.theme.secondaryContrastText,
    };
};

export default connect(mapStateToProps)(GlobalTheme);