import React from 'react';
import { connect } from 'react-redux';

import classes from './Footer.module.css';

const Footer = props => {
    return (
        <div className={classes.Footer} style={{backgroundColor: props.primaryMain}}>
            <span style={{color: props.primaryContrastText, paddingRight: '10px'}}>Copyright 2010-2022 Magniss Asia Pacific Pte Ltd</span>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        primaryMain: state.theme.primaryMain,
        primaryContrastText: state.theme.primaryContrastText,
        userDisplayName: state.auth.displayName
    };
};

export default connect(mapStateToProps)(Footer);