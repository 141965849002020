import React from 'react';

import useWindowDimensions from '../../shared/windowDimensions';

import classes from './Background.module.css';
import bgImage from '../../assets/images/login_bg.png';

const Background = props => {
    const { height } = useWindowDimensions();

    return (
        <div className={classes.Background} style={{
            backgroundImage: "url(" + bgImage + ")",
            height: height
        }}>
            {props.children}
        </div>
    );
}

export default React.memo(Background);