import axios from '../../axios-instance';

import * as actionTypes from './actionTypes';

export const fetchLicenseInfoSuccess = (licenseInfo) => {
    return {
        type: actionTypes.FETCH_LICENSE_INFO_SUCCESS,
        licenseInfo: licenseInfo
    };
};

export const fetchLicenseInfoFail = (error) => {
    return {
        type: actionTypes.FETCH_LICENSE_INFO_FAIL,
        error: error
    };
};

export const fetchLicenseInfoStart = () => {
    return {
        type: actionTypes.FETCH_LICENSE_INFO_START,
        error: null
    };
};

export const fetchLicenseInfo = () => {
    return dispatch => {
        dispatch(fetchLicenseInfoStart());
        const version = 2;
        const url = `/api/v${version}/auth/getactivelicenseinfo`;
        axios.get(url)
            .then(res => {
                dispatch(fetchLicenseInfoSuccess(res.data));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchLicenseInfoFail(errMsgData));
            });
    };
};

export const activateLicenseInfoSuccess = (licenseInfo) => {
    return {
        type: actionTypes.ACTIVATE_LICENSE_INFO_SUCCESS,
        licenseInfo: licenseInfo
    };
};

export const activateLicenseInfoFail = (error) => {
    return {
        type: actionTypes.ACTIVATE_LICENSE_INFO_FAIL,
        error: error
    };
};

export const activateLicenseInfoStart = () => {
    return {
        type: actionTypes.ACTIVATE_LICENSE_INFO_START,
        error: null
    };
};

export const activateLicenseInfo = (licenseKey) => {
    return dispatch => {
        dispatch(activateLicenseInfoStart());
        const version = 2;
        const url = `/api/v${version}/auth/activatelicense`;
        const data = {
            activationKey: licenseKey
        };
        axios.post(url, data)
            .then(res => {
                if (res.data && res.data.licenseInfoMessage !== 'Your license is now activated successfully.') {
                    const error = {
                        message: res.data.licenseInfoMessage
                    }
                    dispatch(activateLicenseInfoFail(error));
                }
                else {
                    dispatch(activateLicenseInfoSuccess(res.data));
                }
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(activateLicenseInfoFail(errMsgData));
            });
    };
};

export const fetchProfilesSuccess = (profiles, defaultProfile) => {
    return {
        type: actionTypes.FETCH_PROFILES_SUCCESS,
        profiles: profiles,
        defaultProfile: defaultProfile
    };
};

export const fetchProfilesFail = (error) => {
    return {
        type: actionTypes.FETCH_PROFILES_FAIL,
        error: error
    };
};

export const fetchProfilesStart = () => {
    return {
        type: actionTypes.FETCH_PROFILES_START,
        error: null
    };
};

export const fetchProfiles = () => {
    return dispatch => {
        dispatch(fetchProfilesStart());
        const version = 1;
        const url = `/api/v${version}/auth/getprofiles`;
        axios.get(url)
            .then(res => {
                const fetchedProfiles = res.data['allProfiles'];
                let defaultProfile = res.data['defaultProfile'];
                dispatch(fetchProfilesSuccess(fetchedProfiles, defaultProfile));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(fetchProfilesFail(errMsgData));
            });
    };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
        error: null
    };
};

export const authSuccess = (accessToken, refreshToken, userName, profile, displayName, email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        accessToken: accessToken,
        refreshToken: refreshToken,
        userName: userName,
        displayName: displayName,
        email: email,
        selectedProfile: profile
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const auth = (userName, password, profile) => {
    return dispatch => {
        dispatch(authStart());
        const version = 2;
        const authData = {
            userName: userName,
            password: password,
            profile: profile
        };
        let url = `/api/v${version}/auth/authenticate`;

        axios.post(url, authData)
            .then(response => {
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('refreshToken', response.data.refreshToken);
                localStorage.setItem('selectedProfile', response.data.profile);
                localStorage.setItem('userName', response.data.userName);
                localStorage.setItem('displayName', response.data.displayName);
                localStorage.setItem('email', response.data.email);
                dispatch(authSuccess(
                    response.data.accessToken,
                    response.data.refreshToken,
                    response.data.userName,
                    response.data.profile,
                    response.data.displayName,
                    response.data.email));
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(authFail(errMsgData));
            });
    };
};

export const forceLogoutSessionSuccess = () => {
    return {
        type: actionTypes.FORCE_LOGOUT_SESSION_SUCCESS,
        loading: false,
        error: null
    };
};

export const forceLogoutSessionFail = (error) => {
    return {
        type: actionTypes.FORCE_LOGOUT_SESSION_FAIL,
        loading: false,
        error: error
    };
};

export const forceLogoutSessionStart = () => {
    return {
        type: actionTypes.FORCE_LOGOUT_SESSION_START,
        loading: true,
        error: null
    };
};

export const forceLogoutSession = (userName, profile, sessionId, systemName) => {
    return dispatch => {
        dispatch(forceLogoutSessionStart());
        const version = 2;
        const url = `/api/v${version}/auth/forcelogoutactivesession`;
        const data = {
            userName: userName,
            profile: profile,
            activeSessionToken: sessionId,
            activeSystemName: systemName
        };
        axios.post(url, data)
            .then(res => {
                dispatch(forceLogoutSessionSuccess());
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(forceLogoutSessionFail(errMsgData));
            });
    };
};

export const logoutStart = () => {
    return {
        type: actionTypes.LOGOUT_START,
        error: null
    };
};

export const logoutSuccess = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('selectedProfile');
    localStorage.removeItem('userName');
    localStorage.removeItem('displayName');
    localStorage.removeItem('email');
    return {
        type: actionTypes.LOGOUT_SUCCESS,
        accessToken: null,
        refreshToken: null,
        userName: null,
        displayName: null,
        email: null
    };
};

export const logoutFail = (error) => {
    return {
        type: actionTypes.LOGOUT_FAIL,
        error: error
    };
};

export const logout = (userName, profile) => {
    return dispatch => {
        dispatch(logoutStart());
        const version = 2;
        const url = `/api/v${version}/auth/logout`;
        const data = {
            userName: userName,
            profile: profile
        };
        axios.post(url, data)
            .then(response => {
                dispatch(logoutSuccess());
            })
            .catch(err => {
                let errMsgData = {
                    message: err.message
                };
                if (err.response && err.response.data && err.response.data.message) {
                    errMsgData = err.response.data;
                }
                dispatch(logoutFail(errMsgData));
            });
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
            dispatch(logoutSuccess());
        } else {
            const refreshToken = localStorage.getItem('refreshToken');
            const userName = localStorage.getItem('userName');
            const profile = localStorage.getItem('profile');
            const displayName = localStorage.getItem('displayName');
            const email = localStorage.getItem('email');
            dispatch(authSuccess(accessToken, refreshToken, userName, profile, displayName, email));
        }
    };
};

export const clearAuthError = () => {
    return {
        type: actionTypes.CLEAR_AUTH_ERROR,
        error: null
    };
};